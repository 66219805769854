ict.dsp02 = (function($){

    var $dsp;

    function init() {
        $dsp = $('.dsp-txt[data-mobile-img]');

        $dsp.each(function(){
           handleMobileImg($(this));
        });
        $(window).on("resize", function(){
            $dsp.each(function(){
                handleMobileImg($(this));
            });
        });


    }

    function handleMobileImg($el) {
        var imgUrl = $el.attr('data-mobile-img');
        var windowWidth = $(window).width();
        if(windowWidth < 768) {
            $el.css({
                'background-image': 'url('+imgUrl+')',
                'background-size': 'cover'
            });
        }
        else {
            $el.css({
                'background-image': 'none'
            });
        }
    }

    return {
        init: init
    };

})(jQuery);

$(document).ready(ict.dsp02.init);