var ict = {};

(function ($) {
    $.fn.cssNumber = function (prop) {
        var v = parseInt(this.css(prop), 10);
        return isNaN(v) ? 0 : v;
    };
})(jQuery);

function initMap() {

    if ($("#map-it-1").length > 0)
        initMapIt1();
    if ($("#map-it-2").length > 0)
        initMapIt2();
    if ($("#map-it-3").length > 0)
        initMapIt3();
    if ($("#map-de").length > 0)
        initMapDe();
    if ($("#map-es").length > 0)
        initMapEs();
    if ($("#map-fr").length > 0)
        initMapFr();
    if ($("#map-pl").length > 0)
        initMapPl();
    if ($("#map").length > 0)
        initMapBase();
}

// Check su paese di candidatura in base al quale viene costruito il form corretto in linea con le specifiche GDPR per ogni paese

function checkCandidateCountry() {

    var $form = $('#frm-careers');
    var $countrySelect = $form.find('select[name="vacancyCountry"]');


    $countrySelect.change(function () {

        if ($(this).val() != "") {
            $form.find('.form-builder').removeClass('is-hidden');
        } else {
            $form.find('.form-builder').addClass('is-hidden');
        }

        return buildForm($(this).val());

    });

    function buildForm($country) {

        if ($country === 'germany') {
            $form.find('.input-group.date').parent().parent().addClass('is-hidden');
            $form.find('select[name="gender"]').parent().parent().addClass('is-hidden');
            $form.find('input[name="district"]').parent().parent().addClass('is-hidden');
        } else {
            $form.find('.input-group.date').parent().parent().removeClass('is-hidden');
            $form.find('select[name="gender"]').parent().parent().removeClass('is-hidden');
            $form.find('input[name="district"]').parent().parent().removeClass('is-hidden');
        }

        // Consenso facoltativo per germania e polonia
        if ($country === 'germany') {
            $form.find('.only-de').removeClass('is-hidden');
        } else {
            $form.find('.only-de').addClass('is-hidden');
        }

        // Consenso facoltativo per germania e polonia
        if ($country === 'poland') {
            $form.find('.only-pl').removeClass('is-hidden');
        } else {
            $form.find('.only-pl').addClass('is-hidden');
        }

        // Consenso obbligatorio specifico per spagna e polonia
        if ($country === 'spain' || $country === 'poland') {
            $form.find('.only-es-pl').removeClass('is-hidden');
        } else {
            $form.find('.only-es-pl').addClass('is-hidden');
        }
    }

}


var EducationFilter = (function ($, window) {
    $.fn.hidden = function (hidden) {
        return this.each(function () {
            if (hidden) {
                $(this).hide();
            } else {
                $(this).show();
            }
        });
    };

    function EducationFilter() {
        this.$form = $('#frm-careers');
        this.$education = $('select[name=education]', this.$form);
        this.$orientation = $('select[name=orientation]', this.$form);

        this.items = $.type(window['educationRelationships'] === 'object') ? window['educationRelationships'] : {};
        this.educationKeys = this.listEducationKeys();

        this.optionsOrientation = this.$orientation.find("option");
    }

    EducationFilter.prototype = {
        bindAll: function () {
            if (!this.$form.length) {
                return;
            }
            var orientation = this.$orientation.val();

            this.setEducationOptions();
            this.setOrientationOptions();

            this.$education.trigger('change');
            this.$orientation.val(orientation);


        },

        listEducationKeys: function () {
            var educations = [];

            for (var education in this.items) {
                educations.push(education);
            }

            return educations;
        },

        isOptionHidden: function ($option, values) {
            var value = $option.attr('value'),
                hidden = value && ($.inArray(value, values || []) == -1);

            return hidden;
        },

        setEducationOptions: function () {
            var instance = this;

            $('option', this.$education).each(function () {
                var $option = $(this),
                    value = $option.attr('value'),
                    hidden = instance.isOptionHidden($option, instance.educationKeys),
                    orientations = hidden ? [] : (instance.items[value] || []);

                //$option.hidden(hidden);
                if (hidden) {
                    $option.remove();
                }
                $option.data('orientations', orientations);
            });
        },

        setOrientationOptions: function () {
            instance = this;

            this.$education.on('change', function () {
                var $select = $(this),
                    orientations = $('option:selected', $select).data('orientations') || [],
                    emptyOrientations = true;


                instance.$orientation.empty();

                $(instance.optionsOrientation).each(function (idx, val) {
                    var $option = $(val);
                    hidden = instance.isOptionHidden($option, orientations);
                    if ($option.attr('value') == "") {
                        instance.$orientation.append($option);
                    } else if (!hidden) {
                        instance.$orientation.append($option);
                        emptyOrientations = false;
                    }
                });


                /*$('option', instance.$orientation).each(function() {
                 var $option = $(this),
                 value = $option.attr('value'),
                 hidden = instance.isOptionHidden($option, orientations);

                 $option.hidden(hidden);

                 emptyOrientations = emptyOrientations && (hidden || !value);
                 });*/

                instance.$orientation.prop('disabled', emptyOrientations);
                instance.$orientation.val('');
            });
        }
    };

    return EducationFilter;
})(jQuery, window);


$(document).ready(function () {

    ict.igr04.init();

    var ie9 = false;
    if ($(".ie9").length > 0) {
        ie9 = true;
    }

    //bugfix per cursore lampeggiante di ie http://stackoverflow.com/questions/7917592/html-cursor-showing-in-readonly-input-text
    $('input[readonly]').focus(function () {
        this.blur();
    });

    var $cookiePolicyAnchor = $('#cookie-policy-link'),
        cookiePolicyUrl = $('#cookie-bar,#cookie-bar-edit').data('cookie-policy-link');
    if ($cookiePolicyAnchor.length && cookiePolicyUrl) {
        $cookiePolicyAnchor.attr('href', cookiePolicyUrl);
    }

    var educationFilter = new EducationFilter();
    educationFilter.bindAll();

    //Setto lingua current via JS
    var currLang = $(".lang-nav .dropdown-lang > .current").text();
    $(".lang-nav > .current").html('<span>' + currLang + '</span>');


    //Setto lingua per datepicker
    var datepicker = $('.input-group.date');
    if (datepicker.length) {

        datepicker.datepicker({
            format: "dd/mm/yyyy",
            startView: 2,
            language: currLang.toLowerCase(),
            defaultViewDate: {year: 1985}
        });
    }

    if ($(".top-01").length + $(".top-04").length == 0) {
        $("body").addClass("inner");
    }
    if ($(".top-04").length) {
        $("body").addClass("top-video");
    }

    //se top proporzionale
    var $tops = $(".top-01, .top-02, .top-03")
    if ($tops.length) {

        var w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (w > 768) {


        } else {

            $.each($tops, function (idx, val) {
                var overContainer = $(val).find(".over-container");
                overContainer.css("height", $(val).find(".img-container img").height());
            })
        }

        var rtime;
        var timeout = false;
        var delta = 200;
        $(window).resize(function () {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }

        });

        function resizeend() {
            if (new Date() - rtime < delta) {
                setTimeout(resizeend, delta);
            } else {
                timeout = false;
                var w = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

                if (w > 768) {
                    $(".over-container").css("height", "");
                } else {
                    $.each($tops, function (idx, val) {
                        var overContainer = $(val).find(".over-container");
                        overContainer.css("height", $(val).find(".img-container img").height());
                    })
                }
            }
        }
    }


    var script = document.createElement('script');
    script.type = 'text/javascript';

    script.src = 'https://maps.googleapis.com/maps/api/js?&key=' + ENV.gMapsApiKey + '&' +
        'callback=initMap';
    document.body.appendChild(script);

    $(".block-list .single-block").matchHeight();

    //SWIPER Carousels and sliders

    if ($('.top-01 .swiper-container .swiper-slide').length > 1) {
        var mySwiper = new Swiper('.top-01 .swiper-container', {
            // Optional parameters
            loop: true,
            //	slidesPerGroup: 1,
            //	slidesPerView: 1,
            pagination: '.top-01 .swiper-pagination',
            paginationClickable: true,
            nextButton: '.top-01 .swiper-button-next',
            prevButton: '.top-01 .swiper-button-prev',
            centeredSlides: true,
            autoplay: 3500,
            speed: 700,
            autoplayDisableOnInteraction: true,
            parallax: true,
            simulateTouch: false,
            followFinger: false,
        });
    }

    if ($('.top-02 .swiper-container .swiper-slide').length > 1) {
        var mySwiper = new Swiper('.top-02 .swiper-container', {
            // Optional parameters
            loop: true,
            //	slidesPerGroup: 1,
            //	slidesPerView: 1,
            pagination: '.top-02 .swiper-pagination',
            paginationClickable: true,
            nextButton: '.top-02 .swiper-button-next',
            prevButton: '.top-02 .swiper-button-prev',
            centeredSlides: true,
            autoplay: 3500,
            speed: 700,
            autoplayDisableOnInteraction: true,
            parallax: true,
            simulateTouch: false,
            followFinger: false,
        });
    }


    var slidesPerView_dsp = Math.min(5, $('.dsp-03 .carousel .swiper-slide').length);

    var carousel = new Swiper('.dsp-03 .carousel', {
        slidesPerView: slidesPerView_dsp,
        spaceBetween: 0,
        /*centeredSlides: true,*/
        nextButton: '.carousel-button-next',
        prevButton: '.carousel-button-prev',
        breakpoints: {

            480: {
                slidesPerView: 1,
                spaceBetweenSlides: 10
            },

            768: {
                slidesPerView: 2,
                spaceBetweenSlides: 20
            },

            960: {
                slidesPerView: 3,
                spaceBetweenSlides: 30
            }
        }
    });


    var slidesPerView_cnt_f14 = Math.min(5, $('.cnt-f-14 .carousel .swiper-slide').length);

    var carousel = new Swiper('.cnt-f-14 .carousel', {
        slidesPerView: slidesPerView_cnt_f14,
        spaceBetween: 0,
        /*centeredSlides: true,*/
        nextButton: '.carousel-button-next',
        prevButton: '.carousel-button-prev',
        breakpoints: {

            480: {
                slidesPerView: 1,
                spaceBetweenSlides: 10
            },

            768: {
                slidesPerView: 2,
                spaceBetweenSlides: 20
            },

            960: {
                slidesPerView: 3,
                spaceBetweenSlides: 30
            }
        }
    });

    $(".cnt-f-14 .open").click(function () {
        var idx = $(this).index(".cnt-f-14 .open");
        if (!$(".cnt-f-14 .expandible").eq(idx).hasClass("opened")) {
            $(".cnt-f-14 .expandible.opened").slideUp();
            $(".cnt-f-14 .expandible").eq(idx).slideDown();
            $(".cnt-f-14 .expandible").removeClass("opened");
            $(".cnt-f-14 .expandible").eq(idx).addClass("opened");
        } else {
            $(".cnt-f-14 .expandible").eq(idx).slideUp();
            $(".cnt-f-14 .expandible").eq(idx).removeClass("opened");
            ev.preventDefault();
        }

    });

    $(".cnt-f-14 .close").click(function (ev) {
        $(this).parents(".cnt-f-14 .expandible").slideUp();
        $(this).parents(".cnt-f-14 .expandible").removeClass("opened");
        ev.preventDefault();
    });


    $(".cnt-f-13 .open").click(function (ev) {
        $(this).find('.expandible').slideDown();
        $(this).parents(".cnt-f-13").removeClass('closed').addClass("opened");
        ev.preventDefault();

    });

    $(".cnt-f-13 .close").click(function (ev) {
        $(this).parents(".cnt-f-13").find(".expandible").slideUp();
        $(this).parents(".cnt-f-13").removeClass("opened").addClass('closed');
        ev.preventDefault();
    });

    $.each($('.igr-01'), function (idx, val) {
        var slidesPerView_igr01 = Math.min(5, $(val).find('.swiper-slide').length);

        var carousel_igr = new Swiper('.igr-01:eq(' + idx + ') .carousel', {
            slidesPerView: slidesPerView_igr01,
            spaceBetween: 0,
            nextButton: '.igr-01:eq(' + idx + ') .igr-carousel-button-next',
            prevButton: '.igr-01:eq(' + idx + ') .igr-carousel-button-prev',
            breakpoints: {

                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 10
                },

                768: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 20
                },

                960: {
                    slidesPerView: 3,
                    spaceBetweenSlides: 30
                }
            }
        });
    });

    $.each($('.lst-f-02'), function (idx, val) {

        var slidesPerView_lst_f = Math.min(5, $(val).find('.swiper-slide').length);


        var carousel_lst_f = new Swiper('.lst-f-02:eq(' + idx + ') .carousel', {
            slidesPerView: slidesPerView_lst_f,
            spaceBetween: 0,

            nextButton: '.lst-f-02:eq(' + idx + ') .carousel-button-next',
            prevButton: '.lst-f-02:eq(' + idx + ') .carousel-button-prev',
            loop: false,
            breakpoints: {

                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 10
                },

                768: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 20
                },

                960: {
                    slidesPerView: 3,
                    spaceBetweenSlides: 30
                }
            }
        });

    });

    //NAV-02
    $.each($('.nav-02'), function (idx, val) {
        var slidesPerView_nav = Math.min(5, $(val).find('.swiper-slide').length);

        var carousel_nav = new Swiper('.nav-02:eq(' + idx + ') .carousel', {
            slidesPerView: slidesPerView_nav,
            spaceBetween: 0,
            /*centeredSlides: true,*/
            nextButton: '.nav-02:eq(' + idx + ') .carousel-button-next',
            prevButton: '.nav-02:eq(' + idx + ') .carousel-button-prev',
            loop: false,
            breakpoints: {

                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 10
                },

                768: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 10
                },

                960: {
                    slidesPerView: 3,
                    spaceBetweenSlides: 12
                },
                1270: {
                    slidesPerView: 4,
                    spaceBetweenSlides: 25
                }
            }
        });
    });

    //NAV-03
    if (ie9) {
        var slidesPerView_nav3 = Math.min(5, $('.nav-03 .carousel .swiper-slide').length);

        var carousel_nav_03 = new Swiper('.nav-03 .carousel', {
            slidesPerView: slidesPerView_nav3,
            spaceBetween: 0,
            /*centeredSlides: true,*/
            nextButton: '.nav-03 .carousel-button-next',
            prevButton: '.nav-03 .carousel-button-prev',
            loop: false,
            breakpoints: {

                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 10
                },

                768: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 20
                },

                960: {
                    slidesPerView: 3,
                    spaceBetweenSlides: 30
                }
            }
        });

    } else {
        var carousel_nav_03 = new Swiper('.nav-03 .carousel', {
            slidesPerView: "auto",
            spaceBetween: 0,
            /*centeredSlides: true,*/
            nextButton: '.nav-03 .carousel-button-next',
            prevButton: '.nav-03 .carousel-button-prev',
            loop: false,
        });
    }

    //Timeline
    var timeline = new Swiper('.timeline', {
        // Optional parameters
        slidesPerView: 1,
        spaceBetween: 0,

        speed: 700,
        effect: 'slide',
        //centeredSlides: true,
        onSlideChangeEnd: function (swiper) {
            var id = swiper.activeIndex;
            $(".timeline-control").removeClass("selected");
            $(".timeline-control:eq(" + id + ")").addClass("selected");
            setMobileTimeline();
        }
    });

    function setMobileTimeline() {
        var id = timeline.activeIndex;
        var ul = $(".timeline .key-facts:eq(" + id + ") ul").clone();
        $(".block-key-facts-under").empty();
        $(".block-key-facts-under").append(ul);
    }

    setMobileTimeline();

    //Controlli timeline
    $(".timeline-control").click(function () {

        var idx = $(this).data("slide");

        timeline.slideTo(idx);
        //x ie9
        if (ie9) {
            $(".timeline-control").removeClass("selected");
            $(".timeline-control:eq(" + idx + ")").addClass("selected");
        }
        return false;
    });


    // POPUP


    //LOCALIZZAZIONE POPUP
    // Add it after jquery.magnific-popup.js and before first initialization code
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Close (Esc)', // Alt text on close button
        tLoading: 'Loading...', // Text that is displayed during loading. Can contain %curr% and %total% keys
        gallery: {
            tPrev: 'Previous (Left arrow key)', // Alt text on left arrow
            tNext: 'Next (Right arrow key)', // Alt text on right arrow
            tCounter: 'Foto %curr% di %total%' // Markup for "1 of 7" counter
        },
        image: {
            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
        },
        ajax: {
            tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
        }
    });


    // non c'è il classico data-rel di fancybox:
    // http://stackoverflow.com/questions/16654120/multiple-galleries-with-magnific-popup

    $('.popup-gallery').each(function () { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', //Come quella sopra ma con gli items delegati in tag <a>
            type: 'image',
            markup: '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                '<div class="mfp-bottom-bar">' +
                '<div class="mfp-counter"></div>' +
                '<div class="mfp-title"></div>' +
                '</div>' +
                '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            closeMarkup: '<button title="%title%" type="button" class="lightbox-close mfp-close"></button>',
            closeBtnInside: false,

            gallery: {
                arrowMarkup: '<button title="%title%" type="button" class="lightbox-%dir%"></button>', // markup of an arrow button
                enabled: true
            },

            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            },

            // Delay in milliseconds before popup is removed
            removalDelay: 300,
            // Class that is added to popup wrapper and background
            // make it unique to apply your CSS animations just to this exact popup
            mainClass: 'mfp-fade',
        });
    });


    //Video Popup
    //Aggiungo classe per video icon
    $(".popup-youtube").parent(".img-container").addClass("video-icon");

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 250,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        // fixedContentPos: false
        showCloseBtn: true,
        closeMarkup: '<button title="%title%" type="button" class="lightbox-close mfp-close"></button>',
        closeBtnInside: false,
        patterns: { // remove related videos
            youtube: {
                index: 'youtube.com',
                id: 'v=',
                src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
            }
        }
    });


    function injectBackgrounds(obj) {
        var topWrappers = obj.find('.img-container');
        topWrappers.each(function (i, el) {
            var that = $(el);

            var imgSRC = that.find('img').attr('src');
            var posLeft = that.attr('data-left');


            that.attr('style', 'background-image: url("' + imgSRC + '") !important;background-size: cover !important;');

            console.log("posLeft: " + posLeft);
            if (typeof posLeft != 'undefined' && parseInt(posLeft) >= 0 && parseInt(posLeft) <= 100) {
                that.attr('style', that.attr('style') + 'background-position-x: ' + posLeft + '% !important;background-size: cover !important');
                //imgWrapper.css('background-position-x', posLeft + '%');
            } else {

            }
        });
    }

    var sectionTOP = $(document).find('section.top');
    sectionTOP.each(function (i, el) {
        var that = $(el);
        injectBackgrounds(that);
        /*var imgWrapper = that.find('.img-container');
        var posLeft = that.find('.row').attr('data-left');
        if (typeof posLeft != 'undefined' && parseInt(posLeft) >= 0 && parseInt(posLeft) <= 100) {
            imgWrapper.css('background-position-x', posLeft + '%');
        }*/
    });


    var menuContent = '<ul class="header-new-list">' + $('.header-new-logo + *').html() + '</ul>' + $('.sub-wrapper').html();
    var sidr = $('.side-menu');
    sidr.html(menuContent);
    var btnMenu = $('.btn-menu');

    var allDropdownMenus = $('a.dropdown-nav');
    allDropdownMenus.click(function (e) {
        e.preventDefault();
        if ($(this).parent().hasClass('open')) {
            allDropdownMenus.parent().removeClass('open');
            $(this).parent().removeClass('open');
        } else {
            allDropdownMenus.parent().removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    var allDropdownSubMenus = $('a.dropdown-nav-sub');
    allDropdownSubMenus.click(function (e) {
        e.preventDefault();
        if ($(this).parent().hasClass('open')) {
            allDropdownSubMenus.parent().removeClass('open');
            $(this).parent().removeClass('open');
        } else {
            allDropdownSubMenus.parent().removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    btnMenu.click(function () {
        if (!sidr.hasClass('active')) {
            sidr.slideDown().addClass('active');
        } else {
            sidr.slideUp().removeClass('active');
        }
    });


    /*

     //MENU SIDR
     $('.btn-menu').sidr({
     displace: false,
     side: 'right',
     speed: 280,
     onOpen: function () {
     $('.sidr').css("height", $(document).height());
     $('.btn-menu').hide();
     },
     onClose: function () {
     $('.btn-menu').show();
     //Resetto il menu
     $(".sidr ul ul").slideUp();
     $(".sidr .active").removeClass("active");
     },
     });

     $('.btn-menu-close').click(function () {
     $.sidr('close', 'sidr');
     });
     $(".has-submenu").click(function () {
     var obj = $(this);
     if (obj.hasClass("active")) {
     obj.parent().find("ul").slideUp();
     obj.parent().find(".active").removeClass("active");

     } else {

     //tolgo a tutti i fratelli e discendenze dei fratelli classe active e slideUp
     obj.parents("li").siblings().find("ul").slideUp();
     obj.parents("li").siblings().find(".active").removeClass("active");
     obj.addClass("active");
     obj.siblings("ul").slideDown();
     }
     });

     $(window).resize(function () {
     $('.sidr').css("height", $(document).height());
     });

     */

    //MAPPA NAV-01
    //HOTSPOTS
    if (typeof hotspots !== 'undefined') {

        $.each(hotspots, function (key, value) {

            var ha = $("<div class='ha' data-rel='nation-" + key + "'></div>");

            var aCircle = $("<div class='circle'></div>");
            ha.append(aCircle);

            ha.css("top", value.top);
            ha.css("left", value.left);
            $(".nav-01 .ha_container").append(ha);

            var tooltip = $("<div class='my-tooltip" + " nation-" + key + "'></div>");
            var captionBlock = $("<div class='tooltip-caption-block'></div>");
            var sectionTitle = $("<div class='section-title'>" + value.title + "</div>");
            var where = $("<div class='where'>" + value.where + "</div>");
            var iconPlus = $("<a href='" + value.link + "'><div class='icon-plus-2'></div></a>");
            var hotspotSizeSmall = (value.hotspotSize == "small") ? true : false;
            var hr = $("<hr>");
            var text = $("<p>" + value.text + "</p>");

            if (hotspotSizeSmall) {
                ha.addClass("ha--small");
            }
            tooltip.append(captionBlock);
            captionBlock.append(sectionTitle);
            captionBlock.append(where);
            captionBlock.append(iconPlus);
            captionBlock.append(hr);
            captionBlock.append(text);

            tooltip.css("top", value.top);
            tooltip.css("left", value.left);
            $(".nav-01 .tooltips").append(tooltip);
        });
    }

    $(".cnt-f-13 .open").click(function (ev) {
        $(this).next(".expandible").slideDown();
        ev.preventDefault();
        $(this).hide();
    });
    $(".cnt-f-13 .close").click(function (ev) {
        $(this).parent(".expandible").slideUp();
        ev.preventDefault();
        $(this).parent(".expandible").prev(".cnt-f-13 .open").show();
    })

    //CLICK
    var currTooltip = null;
    var currTooltipNav07 = null;

    $("body").on("click", function () {
        $(".my-tooltip, .my-tooltip-nav-07").removeClass("show");
        currTooltip = null;
    });
    $(".nav-01 .ha_container .ha").on("click", function (event) {
        event.stopImmediatePropagation();
        var nation = "." + $(this).data("rel");

        if ($(nation).is(currTooltip)) {
            currTooltip.removeClass("show");
            currTooltip = null;
            return;
        }
        if (currTooltip) {
            currTooltip.removeClass("show");
        }

        currTooltip = $(nation);
        $(nation).addClass("show");
    });

    //
    $(".lang-nav").click(function () {
        $(this).toggleClass("open");
    });

    //NAV 07 HOTSPOTS
    var nav07Data = [];
    var $nav07HotSpot = $(".nav-07-hotspot .caption-block")
    if (typeof hotspotsNav07 !== 'undefined') {

        //Copy placeholder to mobile size
        var placeholder = $(".nav-07-hotspot .map-box .title").text();
        $(".nav-07-hotspot .bg-map-caption .title").text(placeholder);

        $.each(hotspotsNav07, function (key, value) {

            var ha = $("<div class='ha' data-nation ='nation-" + key + "' data-rel='" + key + "'></div>");

            var aCircle = $("<div class='circle'></div>");
            ha.append(aCircle);

            ha.css("top", value.top);
            ha.css("left", value.left);
            $(".nav-07-hotspot .ha_container").append(ha);
            var singleData = {};
            singleData.title = value.title;
            singleData.phone = value.phone;
            singleData.mail = value.mail;
            singleData.address = value.address;
            nav07Data.push(singleData);

            var hotspotSizeSmall = (value.hotspotSize == "small") ? true : false;
            if (hotspotSizeSmall) {
                ha.addClass("ha--small");
            }


            // tooltip
            var tooltip = $("<div class='my-tooltip-nav-07" + " nation-" + key + "'></div>");
            var captionBlock = $("<div class='tooltip-caption-block'></div>");
            var sectionTitle = $("<div class='section-title'>" + value.title + "</div>");
            var where = $("<div class='where'>" + value.where + "</div>");
            var iconPlus = $("<a href='" + value.link + "'><div class='icon-plus-2'></div></a>");
            var hotspotSizeSmall = (value.hotspotSize == "small") ? true : false;
            var hr = $("<hr>");
            var text = $("<p>" + value.text + "</p>");

            if (hotspotSizeSmall) {
                ha.addClass("ha--small");
            }
            tooltip.append(captionBlock);
            captionBlock.append(sectionTitle);
            captionBlock.append(where);
            captionBlock.append(iconPlus);
            captionBlock.append(hr);
            captionBlock.append(text);

            tooltip.css("top", value.top);
            tooltip.css("left", value.left);
            $(".nav-07-hotspot .tooltips").append(tooltip);
        });
    }
    $(".nav-07-hotspot .ha_container .ha").on("click", function (event) {
        event.stopImmediatePropagation();
        var rel = $(this).data("rel");
        //alert(nav07Data[rel].title);
        $nav07HotSpot.empty();

        var tito = $("<div class='title'>" + nav07Data[rel].title + "</div>");
        var mail = (nav07Data[rel].address) ? $("<a href='mailto:" + nav07Data[rel].mail + "' class='email'>" + "E " + nav07Data[rel].mail + "</a>") : "";

        var ph = "";
        $.each(nav07Data[rel].phone, function (idx, val) {
            ph += "<a class='phone'>" + val + "</a>";
        })

        var addr = (nav07Data[rel].address) ? $("<div class='address'>" + nav07Data[rel].address + "</div>") : "";
        $nav07HotSpot.append(tito);
        $nav07HotSpot.append(ph);
        $nav07HotSpot.append(mail);
        $nav07HotSpot.append(addr);

        //$nav07HotSpot. nav07Data[rel].title;
        var nation = "." + $(this).data("nation");
        if ($(nation).is(currTooltipNav07)) {
            currTooltipNav07.removeClass("show");
            currTooltipNav07 = null;
            return;
        }
        if (currTooltipNav07) {
            currTooltipNav07.removeClass("show");
        }
        currTooltipNav07 = $(nation);
        $(nation).addClass("show");

    });


    ;
    var carousel = new Swiper('.dsp-06--slider .slider-box', {
        pagination: '.dsp-06--slider .swiper-pagination',
        paginationClickable: true,
        centeredSlides: true,
        autoplay: 7500,
        speed: 1000,
        autoplayDisableOnInteraction: true,
        parallax: true,
        simulateTouch: false,
        followFinger: false,
        loop: true
    });

    //TODO: Gestire il loop anche su pagination

    /*
     (function(){
     var bulletList = $('.dsp-06--slider').find('.swiper-pagination-bullet');
     console.log(bulletList);
     var firstBullet= $(bulletList[0]);
     console.log(firstBullet);
     var sliderWrapper = $('.dsp-06--slider .slider-box .slider-wrapper');
     console.log(sliderWrapper);
     var lastSlide = sliderWrapper.find('')
     firstBullet.on('click', function(){
     console.log(this);
     })
     }())
     */


    var topMainButtons = $('.swiper-container').find('.swiper-button');
    var topMainPagination = $('.swiper-container').find('.swiper-pagination');

    var realignSwiperItems = debounce(function () {
        var wrapperHeight = $('.swiper-container').find('.img-container').first();
        var buttonsTop = ($(wrapperHeight).height());
        topMainButtons.css('top', (buttonsTop / 2));
        if (window.innerWidth < 767) {
            topMainPagination.css('top', (buttonsTop - 25));
        } else {
            topMainPagination.css('top', 'initial');
        }
    }, 250);

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    var greyBgBlocks = $('.row.no-margin');

    var matchBlocksHeight = debounce(function () {
        greyBgBlocks = $('.row.no-margin');
        $.each(greyBgBlocks, function (i, el) {
            var $currentRow = $(el);
            var $blocks = $currentRow.find('.col-sm-4');
            var $greyBlocks = $currentRow.find('.grey-bg');
            if (window.innerWidth > 767) {
                var maxHeight = 0,
                    j = 0;
                $.each($blocks, function (i, el) {
                    $(el).css('min-height', 'auto');
                    $(el).css('height', 'auto');
                });
                for (j; j < $blocks.length; j++) {
                    var currentBlock = $($blocks[j]);
                    var currentBlockHeight = currentBlock.outerHeight(true);
                    if (currentBlockHeight > maxHeight) {
                        maxHeight = currentBlockHeight;
                    }
                }
                $.each($greyBlocks, function (i, el) {
                    $(el).css('min-height', maxHeight + 'px');
                })
            } else {
                $.each($greyBlocks, function (i, el) {
                    $(el).css('min-height', 'auto');
                })
            }
        });
    }, 250);

    matchBlocksHeight();

    $(window).resize(matchBlocksHeight);

    window.addEventListener('resize', realignSwiperItems);
    realignSwiperItems();

    // form careers check candidate country
    checkCandidateCountry();

    // Form career privacy modal box
    function openPrivacyModalBox() {
        var $btnOpenPrivacyModal = $("a[class^='open-modal-privacy']");
        if ($btnOpenPrivacyModal) {
            $btnOpenPrivacyModal.on('click', function (e) {
                className = $(this).attr('class');
                classSuffix = className.substr("open-modal-privacy".length);
                e.preventDefault();
                $(this).parent().next('.modal-privacy-box'.classSuffix).addClass('is-open');
            })
        }
    }

    function closePrivacyModalBox() {
        var $btnClosePrivacyModal = $('.modal-privacy-box__close');
        if ($btnClosePrivacyModal) {
            $btnClosePrivacyModal.on('click', function (e) {

                e.preventDefault();
                $(this).parent().removeClass('is-open');
            })
        }
    }

    openPrivacyModalBox();
    closePrivacyModalBox();
});
