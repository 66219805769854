ict.igr04 = {
    init:function(){
		var _this = this;
        this.$igr04 = $(".igr-04");		
        
		if(this.$igr04.length > 0){	
			
			//_this.slick = this.$igr04.find(".slick-igr-04");
			
			$(".igr-04 .slick-igr-04").slick(
				{
					slidesToShow: 3,
  					slidesToScroll: 1,
					rows: 2,
					infinite: false,
					responsive: [					
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							rows: 2, 
						}
					},	
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							rows: 1, 
						}
					},
					]
				}
			);
						
			$(window).resize(function() {
				$(".igr-04 .slick-igr-04").slick('slickGoTo', 0, false);
			});
        }
    },

}